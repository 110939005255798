exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-elections-flyer-js": () => import("./../../../src/pages/elections/flyer.js" /* webpackChunkName: "component---src-pages-elections-flyer-js" */),
  "component---src-pages-elections-goals-js": () => import("./../../../src/pages/elections/goals.js" /* webpackChunkName: "component---src-pages-elections-goals-js" */),
  "component---src-pages-elections-index-js": () => import("./../../../src/pages/elections/index.js" /* webpackChunkName: "component---src-pages-elections-index-js" */),
  "component---src-pages-elections-list-js": () => import("./../../../src/pages/elections/list.js" /* webpackChunkName: "component---src-pages-elections-list-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-index-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/index.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-index-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-themas-index-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/themas/index.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-themas-index-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-themas-mobility-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/themas/mobility.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-themas-mobility-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-themas-participation-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/themas/participation.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-themas-participation-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-themas-student-city-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/themas/student-city.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-themas-student-city-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-themas-student-housing-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/themas/student-housing.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-themas-student-housing-js" */),
  "component---src-pages-gemeenteraadsverkiezingen-themas-sustainability-js": () => import("./../../../src/pages/gemeenteraadsverkiezingen/themas/sustainability.js" /* webpackChunkName: "component---src-pages-gemeenteraadsverkiezingen-themas-sustainability-js" */),
  "component---src-pages-goals-js": () => import("./../../../src/pages/goals.js" /* webpackChunkName: "component---src-pages-goals-js" */),
  "component---src-pages-housing-js": () => import("./../../../src/pages/housing.js" /* webpackChunkName: "component---src-pages-housing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-das-index-js": () => import("./../../../src/pages/join-das/index.js" /* webpackChunkName: "component---src-pages-join-das-index-js" */),
  "component---src-pages-party-associations-js": () => import("./../../../src/pages/party/associations.js" /* webpackChunkName: "component---src-pages-party-associations-js" */),
  "component---src-pages-party-board-js": () => import("./../../../src/pages/party/board.js" /* webpackChunkName: "component---src-pages-party-board-js" */),
  "component---src-pages-party-faction-index-js": () => import("./../../../src/pages/party/faction/index.js" /* webpackChunkName: "component---src-pages-party-faction-index-js" */),
  "component---src-pages-party-vision-js": () => import("./../../../src/pages/party/vision.js" /* webpackChunkName: "component---src-pages-party-vision-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-student-teams-js": () => import("./../../../src/pages/student-teams.js" /* webpackChunkName: "component---src-pages-student-teams-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-viewpoints-js": () => import("./../../../src/pages/viewpoints.js" /* webpackChunkName: "component---src-pages-viewpoints-js" */),
  "component---src-pages-vote-js": () => import("./../../../src/pages/vote.js" /* webpackChunkName: "component---src-pages-vote-js" */),
  "component---src-templates-fraction-member-js": () => import("./../../../src/templates/FractionMember.js" /* webpackChunkName: "component---src-templates-fraction-member-js" */),
  "component---src-templates-join-das-option-js": () => import("./../../../src/templates/JoinDASOption.js" /* webpackChunkName: "component---src-templates-join-das-option-js" */),
  "component---src-templates-list-person-page-js": () => import("./../../../src/templates/ListPersonPage.js" /* webpackChunkName: "component---src-templates-list-person-page-js" */),
  "component---src-templates-municipality-party-js": () => import("./../../../src/templates/municipalityParty.js" /* webpackChunkName: "component---src-templates-municipality-party-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/Publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-student-team-js": () => import("./../../../src/templates/StudentTeam.js" /* webpackChunkName: "component---src-templates-student-team-js" */),
  "component---src-templates-update-message-js": () => import("./../../../src/templates/updateMessage.js" /* webpackChunkName: "component---src-templates-update-message-js" */)
}

